<template>
  <section class="tracksipment section--shadow-0">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="margin-bottom: 10px">
          <ul class="nav nav-pills profile-nav bg-light">
            <li role="presentation" class="profile-nav--item" @click="tabSelect('profile')" :class="{'active':tabs.profile}">
              <a href="javascript:void(0)"> <i class="fas fa-user mr-1"></i>{{ getTranslation('profileInfo') }}</a>
            </li>
            <li role="presentation" class="profile-nav--item" @click="tabSelect('company')" :class="{'active':tabs.company}">
              <a href="javascript:void(0)"><i class="fa fa-building" aria-hidden="true"></i>
                {{ getTranslation('companies') }}</a>
            </li>
            <li role="presentation" class="profile-nav--item">
              <a href="javascript:void(0)" @click="()=>{$router.push({name:'portal.client.profile.apps'}).catch(()=>{})}">
                <i class="fas fa-clipboard-list mr-1"></i>{{ getTranslation('appHistory') }}
              </a>
            </li>
            <li role="presentation" class="profile-nav--item">
              <a href="javascript:void(0)" @click="()=>{ $router.push({name:'portal.client.account'}).catch(()=>{}) }">
                <i class="flaticon-people mr-1"></i>{{ getTranslation('profile') }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="row" v-if="tabs.profile">

        <div class="col-md-6 col-sm-12">

          <div class="panel panel-default ">

            <div class="panel-heading bg-white">
              <span class="panel-title">{{ getTranslation('personalInfo') }}</span>

            </div>

            <div class="panel-body table-responsive">


              <table v-if="client.id" class="table table-doc table-borderless">
                <tbody>
                <tr>
                  <th class="table-doc__cell table-doc__cell--head" style="width: 28%">{{ getTranslation('fio') }}:</th>
                  <td class="table-doc__cell" style="width: 60%">{{ client.fullName }}</td>
                  <td class="table-doc__cell"><span class="cursor-pointer icon" @click="openModal('fullName')"><i class="fas fa-edit"></i></span></td>
                </tr>
                <tr>
                  <th class="table-doc__cell table-doc__cell--head" style="width: 28%">{{ getTranslation('username') }}:</th>
                  <td class="table-doc__cell" style="width: 60%">{{ client.user.name }}</td>
                  <td class="table-doc__cell"><span class="cursor-pointer icon" @click="openModal('username')"><i class="fas fa-edit"></i></span></td>
                </tr>
                <tr>
                  <th class="table-doc__cell table-doc__cell--head" style="width: 28%">{{ getTranslation('pin') }}:</th>
                  <td class="table-doc__cell" style="width: 60%">{{ client.pin }}</td>
                  <td class="table-doc__cell"><span class="cursor-pointer icon" @click="openModal('pin')"><i class="fas fa-edit"></i></span></td>
                </tr>
                <tr>
                  <th class="table-doc__cell table-doc__cell--head" style="width: 28%">{{ getTranslation('mobile') }}:</th>
                  <td class="table-doc__cell" style="width: 60%"><span v-if="client.contact.mobileCode">  ({{ client.contact.mobileCode.code }})</span> {{ client.contact.mobile }}</td>
                  <td class="table-doc__cell"><span class="cursor-pointer icon" @click="openModal('mobile')"><i class="fas fa-edit"></i></span></td>
                </tr>
                <tr>
                  <th class="table-doc__cell table-doc__cell--head" style="width: 28%">{{ getTranslation('email') }}:</th>
                  <td class="table-doc__cell" style="width: 60%">{{ client.contact.email }}</td>
                  <td class="table-doc__cell "><span class="cursor-pointer icon" @click="openModal('email')"><i class="fas fa-edit"></i></span></td>
                </tr>
                </tbody>
              </table>

              <div class="row margtop30">
                <div class="col-md-6 col-sm-12">
                  <div class="user-block-item">
                    <div class="title" style="display: block;">{{ getTranslation('bdate') }}</div>
                    <div class="data-block" style="display: block;">{{ client.bdate }} ({{ getTranslation('yearsOld', client.age) }})</div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="user-block-item">
                    <div class="title" style="display: block;">{{ getTranslation('gender') }}</div>
                    <div class="data-block" style="display: block;">{{ getTranslation(client.gender_text) }}</div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="col-md-6 col-sm-12 margbtm30">

          <div class="panel panel-default ">

            <div class="panel-heading bg-white justify-content-between d-flex">
              <span class="panel-title ">{{ getTranslation('requisites') }}</span>
              <div class="btn-group btn-group-sm ml-10" role="group">
                <button type="button" class="btn btn-primary btn-xs" @click="openModal('requisite')">{{ getTranslation('edit') }}</button>
              </div>
            </div>

            <div class="panel-body">

              <table v-if="requisite.id" class="table table-doc table-borderless">
                <tbody>
                <tr>
                  <th class="table-doc__cell table-doc__cell--head" style="width: 35%">{{ getTranslation('title') }}:</th>
                  <td class="table-doc__cell" style="width: 60%">{{ requisite.title }}</td>
                </tr>
                <tr>
                  <th class="table-doc__cell table-doc__cell--head" style="width: 35%">{{ getTranslation('bankName') }}:</th>
                  <td class="table-doc__cell" style="width: 60%">{{ requisite.bank_name }}</td>
                </tr>
                <tr>
                  <th class="table-doc__cell table-doc__cell--head" style="width: 35%">{{ getTranslation('bankBik') }}:</th>
                  <td class="table-doc__cell" style="width: 60%">{{ requisite.bank_bik }}</td>
                </tr>
                <tr>
                  <th class="table-doc__cell table-doc__cell--head" style="width: 35%">{{ getTranslation('personalAccount') }}:</th>
                  <td class="table-doc__cell" style="width: 60%">{{ requisite.personal_account }}</td>
                </tr>
                <tr>
                  <th class="table-doc__cell table-doc__cell--head" style="width: 35%">{{ getTranslation('fio') }}:</th>
                  <td class="table-doc__cell" style="width: 60%">{{ requisite.full_name }}</td>
                </tr>
                </tbody>
              </table>

              <div v-if="!requisite.id" class="btn-group btn-group-sm text-center" role="group">
                <button type="button" @click="openModal('requisite')" class="btn btn-primary">{{ getTranslation('addRequisites') }}</button>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="row" v-if="tabs.company">

        <div class="companies col-md-12 col-sm-12">

          <div class="justify-content-between panel-heading d-flex">
            <span class="panel-title" style="font-size: 18px"> {{ getTranslation('yourCompanies') }} </span>
            <div class="btn-group btn-group-sm ml-10" role="group">
              <button class="btn btn-sm btn-primary company-create--btn" @click="openCompanyModal">
                {{ getTranslation('createCompany') }}
              </button>
            </div>
          </div>

          <div class="my-companies--list">

            <div v-for="item in companies" class="my-companies--item">

              <span class="my-companies--icon">
               <i class="fa fa-building" aria-hidden="true"></i>
              </span>

              <a class="my-companies--item-info">
                <div class="my-companies--title">{{ item.title }}</div>
                <div class="my-companies--address">
                  <span v-if="item.contact">{{ item.contact.address }}</span>
                </div>
              </a>

              <a href="javascript:void(0)" @click="showServiceCateoryModal(item.id)" class="btn btn-sm btn-outline-success">
                {{ getTranslation('sendAppFromCompany') }}
              </a>

            </div>

          </div>

        </div>

      </div>

      <!-- Service Category Modal -->
      <PortalModal
          v-model="showModalCompanny"
          :modal-title="getTranslation('sendAppFromCompany')"
          :ok-button-label="getTranslation('ok')"
          :cancel-button-label="getTranslation('cancel')"
          @ok-button-click="selectServiceCategory"
          @cancelButtonClick="closeModalCompany"
          @input="closeModalCompany"
      >

        <div class="form-group col-12">
          <label class="form__label form__label--block">{{ getTranslation('serviceCategory') }} <span
              class="required">*</span></label>
          <VSelect
              :options="services"
              label="translation"
              :reduce="option=>option.slug"
              v-model="serviceCategory"
              :placeholder="getTranslation('selectFromList')"
          />
        </div>

      </PortalModal>

      <!--Personal Modal -->
      <PortalModal
          v-model="showModal"
          :modal-title="getTranslation('edit')"
          :ok-button-label="getTranslation('save')"
          :cancel-button-label="getTranslation('cancel')"
          @ok-button-click="save"
          @cancelButtonClick="closeModal"
          @input="closeModal"
      >
        <form class="form fh-form" id="profile-form">
          <div v-if="type === 'fullName'">
            <div class="form-group">
              <label>{{ getTranslation('fname') }}</label>
              <input type="text" class="form-control" v-model.trim="form.fname">
            </div>
            <div class="form-group">
              <label>{{ getTranslation('lname') }}</label>
              <input type="text" class="form-control" v-model.trim="form.lname">
            </div>
            <div class="form-group">
              <label>{{ getTranslation('pname') }}</label>
              <input type="text" class="form-control" v-model.trim="form.pname">
            </div>
          </div>

          <div v-if="type === 'pin'" class="form-group">
            <label>{{ getTranslation('pin') }}</label>
            <input type="text" class="form-control" v-model.trim="form.pin">
          </div>

          <div v-if="type === 'username'" class="form-group">
            <label>{{ getTranslation('username') }}</label>
            <input type="text" class="form-control" v-model.trim="form.name">
          </div>

          <div v-if="type === 'mobile'" class="form-group">
            <label>{{ getTranslation('mobile') }}</label>
            <div v-if="showPhonCodes" class="input-group">
              <PhoneCode required :edit-item-id="form.contact.mobile_code_id" v-model="form.contact.mobile_code_id" :items="phoneCodes"/>
              <input class="form-control" :placeholder="getTranslation('mobile')" :class="{'is-invalid':$v.form.contact.mobile.$error}" type="text" v-model.trim="form.contact.mobile">
            </div>

            <div v-if="$v.form.contact.mobile.$error" class="invalid-feedback">
              {{ getTranslation('requiredField') }}
            </div>
          </div>

          <div v-if="type === 'email'" class="form-group">
            <label>{{ getTranslation('email') }}</label>
            <input type="email" class="form-control" v-model.trim="form.contact.email">
          </div>

        </form>
      </PortalModal>

      <!-- Modal -->
      <PortalModal
          v-model="requisiteModal"
          :modal-title="getTranslation('addRequisites')"
          :ok-button-label="getTranslation('save')"
          :cancel-button-label="getTranslation('cancel')"
          @ok-button-click="saveRequisites"
          @cancelButtonClick="closeModal('requisite')"
          @input="closeModal('requisite')"
      >
        <form class="form fh-form" id="requisite-form">

          <div class="form-group">
            <label>{{ getTranslation('title') }} <span>*</span> </label>
            <input type="text" class="form-control" :class="{'is-invalid':$v.requisiteForm.title.$error}" :placeholder="getTranslation('writeSuitableRequisitesTitle')" v-model.trim="requisiteForm.title" required>
          </div>

          <div class="form-group">
            <label>{{ getTranslation('bankName') }} <span>*</span></label>
            <input type="text" class="form-control" :class="{'is-invalid':$v.requisiteForm.bank_name.$error}" v-model.trim="requisiteForm.bank_name" required>
          </div>

          <div class="form-group">
            <label>{{ getTranslation('bankBik') }} <span>*</span></label>
            <input type="text" class="form-control" :class="{'is-invalid':$v.requisiteForm.bank_bik.$error}" v-model.trim="requisiteForm.bank_bik" required>
          </div>

          <div class="form-group">
            <label>{{ getTranslation('personalAccount') }} <span>*</span></label>
            <input type="text" class="form-control" :class="{'is-invalid':$v.requisiteForm.personal_account.$error}" v-model.trim="requisiteForm.personal_account" required>
          </div>

          <h6 class="text-muted">{{ getTranslation('accountOwner') }}</h6>

          <div class="form-group">
            <label>{{ getTranslation('fname') }} <span>*</span></label>
            <input type="text" class="form-control" :class="{'is-invalid':$v.requisiteForm.fname.$error}" v-model.trim="requisiteForm.fname" required>
          </div>

          <div class="form-group">
            <label>{{ getTranslation('lname') }} <span>*</span></label>
            <input type="text" class="form-control" :class="{'is-invalid':$v.requisiteForm.lname.$error}" v-model.trim="requisiteForm.lname" required>
          </div>

          <div class="form-group">
            <label>{{ getTranslation('pname') }} <span>*</span></label>
            <input type="text" class="form-control" :class="{'is-invalid':$v.requisiteForm.pname.$error}" v-model.trim="requisiteForm.pname" required>
          </div>

        </form>
      </PortalModal>

      <PortalModal
          v-model="companyModal"
          :modal-title="getTranslation('edit')"
          :ok-button-label="getTranslation('save')"
          :cancel-button-label="getTranslation('cancel')"
          @ok-button-click="saveCompany"
          @cancelButtonClick="closeCompanyModal"
          @input="closeCompanyModal"
      >

        <form class="form fh-form" id="company-form">
          <div class="form-group">
            <label>{{ getTranslation('title') }} <span>*</span> </label>
            <input type="text" class="form-control" :class="{'is-invalid':$v.companyForm.title.$error}" :placeholder="getTranslation('Company title')" v-model.trim="companyForm.title" required>
          </div>

          <div class="form-group">
            <label>{{ getTranslation('address_company') }} <span>*</span> </label>
            <input type="text" class="form-control" :class="{'is-invalid':$v.companyForm.contact.address.$error}" :placeholder="getTranslation('address_company')"
                   v-model.trim="companyForm.contact.address" required>
          </div>

          <div class="form-group">
            <label>{{ getTranslation('contactName') }} <span>*</span> </label>
            <input type="text" class="form-control" :class="{'is-invalid':$v.companyForm.contact.contact_name.$error}" :placeholder="getTranslation('Contact name')" v-model.trim="companyForm.contact.contact_name"
                   required>
          </div>
          <div class="form-group">
            <label>{{ getTranslation('mobile') }} <span>*</span> </label>
            <div v-if="showPhonCodes" class="input-group">

              <PhoneCode required :edit-item-id="companyForm.contact.mobile_code_id" v-model="companyForm.contact.mobile_code_id" :items="phoneCodes"/>

              <input type="text" class="form-control" v-model="companyForm.contact.mobile">
            </div>
          </div>

        </form>

      </PortalModal>

    </div>
  </section>
</template>

<script>
import SidebarWidget from "./components/SidebarWidget";
import PortalModal from "../components/PortalModal";
import PhoneCodemixin from "@/components/mixins/PhoneCode";
import PhoneCode from "@/components/shared/PhoneCode";
import required from "vuelidate/lib/validators/required";

export default {
  name: "Profile",
  mixins: [ PhoneCodemixin ],
  components: { PhoneCode, PortalModal, SidebarWidget },
  data() {
    return {
      client: { id: null },
      requisite: { id: null },
      company: { id: null },
      showModal: false,
      showModalCompanny: false,
      companyModal: false,
      requisiteModal: false,
      type: '',
      tabs: {
        profile: true,
        company: false,
      },
      serviceCategory: '',
      companies: [],
      services: [],
      form: {
        fname: '',
        client_id: '',
        lname: '',
        pname: '',
        bdate: '',
        email: '',
        gender: '',
        name: '',
        pin: '',
        contact: {
          mobile: '',
          mobile_code_id: 118,
          email: '',
        }
      },
      requisiteForm: {
        client_id: '',
        fname: '',
        lname: '',
        pname: '',
        bank_name: '',
        bank_bik: '',
        personal_account: '',
        title: '',
      },
      companyForm: {
        id: '',
        title: '',
        contact: {
          id: '',
          mobile: '',
          contact_name: '',
          address: '',
          mobile_code_id: 118,
        }
      }
    }
  },
  created() {
    this.fetchClient().then(() => {
      this.fetchRequisite();
      this.fetchCompanies();

      if ( this.$route.query.tab )
        this.tabSelect(this.$route.query.tab)

    });
  },
  methods: {

    fetchCompanies() {
      this.axios.get(this.$urls.portal.clientCompanies.url.replace(':id', this.client.id))
          .then(response => {
            this.companies = response.data.data;
          }).catch(() => {
      });
    },

    openCompanyModal() {
      this.companyModal = true;
    },
    closeCompanyModal() {
      this.companyModal = false;
      this.companyForm = {
        id: '',
        title: '',
        contact: {
          id: '',
          mobile: '',
          mobile_code_id: '',
          contact_name: '',
          address: '',
        }
      };
    },

    showServiceCateoryModal(companyId) {
      this.fetchServices();

      this.showModalCompanny = true;
      this.company.id = companyId;

    },

    closeModalCompany() {
      this.serviceCategory = '';
      this.company.id = null;
      this.showModalCompanny = false;
    },

    selectServiceCategory() {
      if ( !this.serviceCategory ) {
        return this.snotifyError('selectServiceCategory');
      }

      this.$router.push({ name: 'portal.send-application', params: { type: this.serviceCategory }, query: { 'companyId': this.company.id } })
    },

    fetchServices() {
      return this.axios.get(this.$urls.portal.services.url)
          .then(response => {
            this.services = response.data.data.map(item => {
              item.translation = this.getTranslation(item.slug);

              return item;
            });
          }).catch(() => {
          });
    },

    saveCompany() {
      this.$v.companyForm.$touch();

      if ( this.$v.companyForm.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.axios.post(this.$urls.portal.clientAddCompany.url.replace(':id', this.client.id), this.companyForm)
          .then(response => {

            this.snotifySuccess('saveSuccessMsg');
            this.closeCompanyModal();

            this.fetchClient();

          }).catch(() => {
      });

    },
    save() {
      this.$v.form.$touch();

      if ( this.$v.form.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.axios.post(this.$urls.portal.clientUpdate.url, this.form)
          .then(response => {

            this.snotifySuccess('saveSuccessMsg');
            this.closeModal();

            this.fetchClient();

          }).catch(() => {
      });

    },
    saveRequisites() {
      this.$v.requisiteForm.$touch();

      if ( this.$v.requisiteForm.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.axios.post(this.$urls.portal.clientAddRequisite.url.replace(':id', this.client.id), this.requisiteForm)
          .then(response => {

            this.requisite = response.data.data;
            this.snotifySuccess('saveSuccessMsg');
            this.closeModal('requisite')

          }).catch(() => {
      });
    },
    fillForm(type) {
      this.form.client_id = this.client.id;
      this.form.fname = this.client.fname;
      this.form.email = this.client.user.email;
      this.form.pname = this.client.pname;
      this.form.lname = this.client.lname;
      this.form.bdate = this.client.bdate;
      this.form.contact.email = this.client.contact.email;
      this.form.contact.mobile_code_id = this.client.contact.mobile_code_id || 118;
      this.form.contact.mobile = this.client.contact.mobile;
      this.form.pin = this.client.pin;
      this.form.gender = this.client.gender;
      this.form.name = this.client.user.name;
    },
    fillRequisite() {
      this.requisiteForm = { ...this.requisite };
    },
    fillByType(type) {
      switch ( type ) {
        case 'email':
          this.form.contact.email = this.client.contact.email;
          break;
        case 'fullName':
          this.form.fname = this.client.fname;
          this.form.pname = this.client.pname;
          this.form.lname = this.client.lname;
          break;
        case 'mobile':
          this.form.contact.mobile_code_id = this.client.contact.mobile_code_id;
          this.form.contact.mobile = this.client.contact.mobile;
          break;
        case 'pin':
          this.form.pin = this.client.pin;
          break;
        case 'username':
          this.form.name = this.client.user.name;
          break;
      }
    },
    openModal(type = "profile") {

      switch ( type ) {
        case "requisite":
          this.fillRequisite();
          this.requisiteModal = true;
          break;

        default:
          this.showModal = true;
          this.type = type;
          this.fillForm(type);
          break;
      }


    },
    closeModal(type = 'profile') {
      switch ( type ) {
        case "profile":
          this.showModal = false;
          break;
        case "requisite":
          this.requisiteModal = false;
          break;
      }

      this.$v.$reset();
    },

    fetchClient() {
      return this.axios.post(this.$urls.portal.clientProfile.url, { client_id: this.$store.getters['user/getPortalUser'].client.id })
          .then(response => {
            this.client = response.data.data;
          }).catch(() => {
          });
    },
    fetchRequisite() {
      this.axios.get(this.$urls.portal.clientRequisite.url.replace(':id', this.client.id))
          .then(response => {
            this.requisite = response.data.data;
          }).catch(() => {
      });
    }
  },
  validations: {
    companyForm: {
      title: {
        required
      },
      contact: {
        contact_name: {
          required
        },
        mobile: {
          required
        },
        address: {
          required
        },
      },
    },
    form: {
      fname: {
        required
      },
      lname: {
        required
      },
      gender: {
        required
      },
      email: {
        required
      },
      name: {
        required
      },
      contact: {
        email: {
          required
        },
        mobile: {
          required
        },
      }
    },
    requisiteForm: {
      fname: {
        required
      },
      lname: {
        required
      },
      pname: {
        required
      },
      title: {
        required
      },
      bank_name: {
        required
      },
      bank_bik: {
        required
      },
      personal_account: {
        required
      },
    }
  }
}
</script>

<style scoped>
#profile-form label {
  color: #8b8b8b !important;
}

.panel-title {
  color: #000 !important;
}

.profile-nav > li.active a {
  color: #fff !important;
}
</style>